import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../helpers/app-material.module';
import { DialogComponent } from './shared/dialog/dialog.component';
import { LayoutComponent } from './shared/layout/layout.component';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './shared/footer/footer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MandantLogoModule } from './shared/mandant-logo/mandant-logo.module';
import { StockImagesGalleryComponent } from './shared/stock-images-gallery/stock-images-gallery.component';
import { EditVoucherCodesDialogComponent } from './shared/dialog/edit-voucher-codes-dialog/edit-voucher-codes-dialog.component';
import { ShowVoucherCodesComponent } from './shared/dialog/show-voucher-codes/show-voucher-codes.component';
import { ChangeEventStateComponent } from './shared/bottomSheets/change-event-state/change-event-state.component';
import { DeleteDialogComponent } from './shared/dialog/delete-dialog/delete-dialog.component';
import { ChangeBusinessEntityStateComponent } from './shared/bottomSheets/change-business-entity-state/change-business-entity-state.component';
import { ChangeBusinessUserStateComponent } from './shared/bottomSheets/change-business-user-state/change-business-user-state.component';
import { ChangeBusinessUserRoleComponent } from './shared/bottomSheets/change-business-user-role/change-business-user-role.component';
import { ErrorDialogComponent } from './shared/dialog/error-dialog/error-dialog.component';
import { AddHouseHoldmemberComponent } from './shared/dialog/add-house-holdmember/add-house-holdmember.component';
import { ResetPWForUserComponent } from './shared/dialog/reset-pwfor-user/reset-pwfor-user.component';
import { ShowQrCodeComponent } from './shared/dialog/show-qr-code/show-qr-code.component'
import { Page404Component } from './shared/page404/page404.component';
import { AddCategoriesComponent } from './shared/dialog/add-categories/add-categories.component';
import { ShowCardQrCodeComponent } from './shared/dialog/show-card-qr-code/show-card-qr-code.component';
import { DeleteComponent } from './shared/dialog/delete/delete.component';
import { AutoUpdateEventStatusComponent } from './shared/dialog/auto-update-event-status/auto-update-event-status.component';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { ReopenAccountComponent } from './shared/dialog/reopen-account/reopen-account.component';
import { LostPWComponent } from './shared/dialog/lost-pw/lost-pw.component';
import { DelteUserComponent } from './shared/dialog/delte-user/delte-user.component';
import { SearchCustomerComponent } from './shared/dialog/search-customer/search-customer.component';
import { EditMailContactComponent } from './shared/dialog/edit-mail-contact/edit-mail-contact.component';
import { AutoUpdateEventInfoStatusComponent } from './shared/dialog/auto-update-event-status-info/auto-update-event-status.component';
import { DeactivateUserComponent } from './shared/dialog/deactivate-user/deactivate-user.component';
import { ErpSyncMessagesComponent } from './shared/dialog/erp-sync-messages/erp-sync-messages.component';
import { ChangeEventLimitsForStatesComponent } from './shared/dialog/change-event-limits-for-states/change-event-limits-for-states.component';


@NgModule({
  declarations: [
    DialogComponent,
    LayoutComponent,
    NavigationComponent,
    FooterComponent,
    StockImagesGalleryComponent,
    EditVoucherCodesDialogComponent,
    ShowVoucherCodesComponent,
    ChangeEventStateComponent,
    DeleteDialogComponent,
    ChangeBusinessEntityStateComponent,
    ChangeBusinessUserStateComponent,
    ChangeBusinessUserRoleComponent,
    ErrorDialogComponent,
    AddHouseHoldmemberComponent,
    ResetPWForUserComponent,
    ShowQrCodeComponent,
    Page404Component,
    AddCategoriesComponent,
    ShowCardQrCodeComponent,
    DeleteComponent,
    AutoUpdateEventStatusComponent,
    ReopenAccountComponent,
    LostPWComponent,
    DelteUserComponent,
    SearchCustomerComponent,
    EditMailContactComponent,
    AutoUpdateEventInfoStatusComponent,
    DeactivateUserComponent,
    ErpSyncMessagesComponent,
    ChangeEventLimitsForStatesComponent
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MandantLogoModule,
    SharedPipesModule
  ],
  exports: [
   DialogComponent,
    LayoutComponent,
    NavigationComponent,
    FooterComponent
  ]
})
export class SharedComponentsModule { }
