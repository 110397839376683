<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content>
    <form [formGroup]="limitFrom">
        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Entwurft zu Vorschau</mat-label>
            <input matInput formControlName="state_change_draft_preview" type="number">
            <mat-error *ngIf="this.limitFrom.controls['state_change_draft_preview'].hasError('required')">
                Wert <b>muss</b> angebeben werden
            </mat-error>
            <mat-hint>
                Angabe in Tagen
            </mat-hint>
            <!-- <mat-hint>
                Aktionsstart in {{this.limitFrom.getRawValue().state_change_draft_preview}} Tagen oder weniger
            </mat-hint> -->
        </mat-form-field>

        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Vorschau zu Aktiv</mat-label>
            <input matInput formControlName="state_change_preview_active" type="number">
            <mat-error *ngIf="this.limitFrom.controls['state_change_preview_active'].hasError('required')">
                Wert <b>muss</b> angebeben werden
            </mat-error>
            <mat-hint>
                Angabe in Tagen
            </mat-hint>
            <!-- <mat-hint>
                Aktionsstart heute oder überschritten
            </mat-hint> -->
        </mat-form-field>

        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Aktiv zu Abgelaufen</mat-label>
            <input matInput formControlName="state_change_active_expired" type="number">
            <mat-error *ngIf="this.limitFrom.controls['state_change_active_expired'].hasError('required')">
                Wert <b>muss</b> angebeben werden
            </mat-error>
            <mat-hint>
                Angabe in Tagen
            </mat-hint>
            <!-- <mat-hint>
                Aktionsende gestern oder älter  
            </mat-hint> -->
        </mat-form-field>

        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Abgelaufen zu Archiviert</mat-label>
            <input matInput formControlName="state_change_expired_archived" type="number">
            <mat-error *ngIf="this.limitFrom.controls['state_change_expired_archived'].hasError('required')">
                Wert <b>muss</b> angebeben werden
            </mat-error>
            <!-- <mat-hint>
                Aktionsende vor {{this.limitFrom.getRawValue().state_change_expired_archived}} Tagen oder mehr (Wert muss negativ sein)
            </mat-hint> -->
            <mat-hint>
             Angabe in Tagen
            </mat-hint>
        </mat-form-field>
    </form>

</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">{{data.cancelText}}</button>
    <button mat-button mat-raised-button color="primary"  cdkFocusInitial (click)="continue()">{{ data.okText }}</button>
</div>