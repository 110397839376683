import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../dialog.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PropertyService } from 'src/app/services/property.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-change-event-limits-for-states',
  templateUrl: './change-event-limits-for-states.component.html',
  styleUrl: './change-event-limits-for-states.component.scss'
})
export class ChangeEventLimitsForStatesComponent {
  limitFrom: UntypedFormGroup;
  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(
    public dialogRef: MatDialogRef<ChangeEventLimitsForStatesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    private propertyService: PropertyService
  ) {
    this.limitFrom = this.fb.group({
      state_change_draft_preview: ['0', Validators.required],
      state_change_preview_active: ['0', Validators.required],
      state_change_active_expired: ['0', Validators.required],
      state_change_expired_archived: ['0', Validators.required],
    })

    combineLatest([
      this.propertyService.getStateChangeDraftPreview('state_change_draft_preview'),
      this.propertyService.getStateChangeDraftPreview('state_change_preview_active'),
      this.propertyService.getStateChangeDraftPreview('state_change_active_expired'),
      this.propertyService.getStateChangeDraftPreview('state_change_expired_archived'),
    ]).subscribe({
      next: (res: Array<any>) => {
        this.limitFrom.patchValue({
          state_change_draft_preview: res[0].value,
          state_change_preview_active: res[1].value,
          state_change_active_expired: res[2].value,
          state_change_expired_archived: res[3].value
        })
      }
    })

  }

  ngOnInit(): void {
  }
  /**
  * Close the Dialog and emit false
  */
  cancel(): void {
    this.dialogRef.close(false);
  }
  /**
   * Close the Dialog and emit true
   */
  continue() {

    this.limitFrom.markAllAsTouched();
    if (this.limitFrom.invalid) {
      return;
    }
    combineLatest([
      this.propertyService.setStateChangeDraftPreview('state_change_draft_preview', {
        publicId: 'state_change_draft_preview',
        key: 'state_change_draft_preview',
        value: this.limitFrom.getRawValue().state_change_draft_preview
      }),
      this.propertyService.setStateChangeDraftPreview('state_change_preview_active', {
        publicId: 'state_change_preview_active',
        key: 'state_change_preview_active',
        value: this.limitFrom.getRawValue().state_change_preview_active
      }),
      this.propertyService.setStateChangeDraftPreview('state_change_active_expired', {
        publicId: 'state_change_active_expired',
        key: 'state_change_active_expired',
        value: this.limitFrom.getRawValue().state_change_active_expired
      }),
      this.propertyService.setStateChangeDraftPreview('state_change_expired_archived', {
        publicId: 'state_change_expired_archived',
        key: 'state_change_expired_archived',
        value: this.limitFrom.getRawValue().state_change_expired_archived
      }),

    ]).subscribe({
      next: (res: Array<any>) => {
        console.log(res)
        this.dialogRef.close(true)
      },
      error: err => {
        console.log(err)
      }
    })
  }
}
